import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

export default function CoveragePeriodField(): ReactElement {
  const { register, errors } = useFormContext();
  const opts = [10, 15, 25, 30, 'Permanent'];

  return (
    <div className="mb-6">
      <label htmlFor="coverage_period" className="text-center form-label">
        How many years do you need insurance coverage for?
      </label>
      <select
        id="coverage_period"
        name="coverage_period"
        aria-label="Coverage period"
        className="block w-full max-w-xl mx-auto form-input sm:text-sm sm:leading-5"
        ref={register({ required: true })}
      >
        <option value="">Select Coverage</option>
        {opts &&
          opts.map(opt => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
      </select>
      <div className="max-w-xl mx-auto">
        <InputError error={errors.coverage_period?.message} />
      </div>
    </div>
  );
}
