import { DataType } from './data-type';

export enum MediaType {
  MarketplaceAuto = 'MarketplaceAuto',
  MarketplaceCommercial = 'MarketplaceCommercial',
  MarketplaceCriticalIllness = 'MarketplaceCriticalIllness',
  MarketplaceDisability = 'MarketplaceDisability',
  MarketplaceHealthAndDental = 'MarketplaceHealthAndDental',
  MarketplaceHome = 'MarketplaceHome',
  MarketplaceLiability = 'MarketplaceLiability',
  MarketplaceLife = 'MarketplaceLife',
  MarketplaceTravel = 'MarketplaceTravel',
  MarketplaceGroup = 'MarketplaceGroup',
}

export type MediaAttributes = {
  name: string;
  file_name: string;
  type: string;
  expire_at: string;
  instructions: string;
  size: string;
};

export type Media = {
  id: string;
  type: DataType.Media;
  attributes: MediaAttributes;
};

export type MediaResponse = {
  data: Media;
};
