export enum InsuranceType {
  Auto = 'Auto',
  Commercial = 'Commercial',
  CriticalIllness = 'CriticalIllness',
  Disability = 'Disability',
  HealthAndDental = 'HealthAndDental',
  Home = 'Home',
  Liability = 'Liability',
  Life = 'Life',
  Travel = 'Travel',
  Group = 'Group',
}

export type AutoInsurance = {
  type: InsuranceType.Auto;
};

export type CommercialInsurance = {
  type: InsuranceType.Commercial;
};

export type CriticalIllnessInsurance = {
  type: InsuranceType.CriticalIllness;
  coverage_amount: number;
  smoking: boolean;
};

export type DisabilityInsurance = {
  type: InsuranceType.Disability;
  occupation: string;
  job_title: string;
  income: number;
};

export type HealthAndDentalInsurance = {
  type: InsuranceType.HealthAndDental;
  smoking: boolean;
};

export type HomeInsurance = {
  type: InsuranceType.Home;
};

export type LiabilityInsurance = {
  type: InsuranceType.Liability;
};

export type LifeInsurance = {
  type: InsuranceType.Life;
  coverage_period: string | number;
  coverage_amount: number;
  smoking: boolean;
};

export type TravelInsurance = {
  type: InsuranceType.Travel;
};

export type GroupInsurance = {
  type: InsuranceType.Group;
};

export type Insurance =
  | AutoInsurance
  | CommercialInsurance
  | CriticalIllnessInsurance
  | DisabilityInsurance
  | HealthAndDentalInsurance
  | HomeInsurance
  | LiabilityInsurance
  | LifeInsurance
  | GroupInsurance
  | TravelInsurance;
