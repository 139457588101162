import React from 'react';
import { useFormContext } from 'react-hook-form';
import { State } from '../data/models';
import InputError from './input-error';

interface Props {
  provinces: State[];
}

export default function PersonalInfoFields({
  provinces,
}: Props): React.ReactElement {
  const { register, errors } = useFormContext();

  return (
    <>
      <div className="mb-4">
        <label htmlFor="gender" className="text-center form-label">
          Gender
        </label>
        <div className="flex mt-4 justify-evenly">
          <div className="form-radio-checkbox--square">
            <input
              id="male"
              name="gender"
              value="male"
              type="radio"
              className=""
              ref={register}
            />
            <label htmlFor="male" className="">
              <span className="inline-block w-10 h-10 mr-1 border border-grey"></span>
              Male
            </label>
          </div>
          <div className="form-radio-checkbox--square">
            <input
              id="female"
              name="gender"
              value="female"
              type="radio"
              className=""
              ref={register}
            />
            <label htmlFor="female" className="">
              <span className=""></span>
              Female
            </label>
          </div>
        </div>
        <InputError error={errors.gender?.message} />
      </div>

      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="born_at" className="text-center form-label">
            Date of birth
          </label>
          <input
            id="born_at"
            name="born_at"
            type="date"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            ref={register}
          />
          <InputError error={errors.born_at?.message} />
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="state_residence" className="text-center form-label">
          Province
        </label>
        <select
          id="state_residence"
          name="state_residence"
          aria-label="Province"
          className="relative block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 transition duration-150 ease-in-out bg-transparent border-gray-300 rounded-md form-select focus:z-10 form-input font-sansCondensed"
          ref={register}
        >
          <option value="">Select province</option>
          {provinces &&
            provinces.map(province => (
              <option
                className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md cursor-pointer form-input font-sansCondensed"
                key={province.id}
                value={province.id}
              >
                {province.attributes.name}
              </option>
            ))}
        </select>
        <InputError error={errors.state_residence?.message} />
      </div>
    </>
  );
}
