import React, { useState, ReactElement, useEffect } from 'react';
import InsuranceTypeForm from './insurance-type-form';
import ContactForm from './contact-form';
import { MarketplaceService } from '../data';
import { InsuranceType, State } from '../data/models';
import AdditionalInfoForm from './additional-info-form';
import QuoteComplete from './quote-complete';
import SubmitQuoteForm from './submit-quote-form';
import ErrorDialog from './error-dialog';
import { ApiError } from '../data/models/api-error';

export default function Wizard(): ReactElement {
  const [marketplaceId, setMarketplaceId] = useState<string>('');
  const [insuranceSelected, setInsuranceSelected] = useState<string[]>([]);
  const [withPersonalFields, setWithPersonalFields] = useState<boolean>(true);
  const [showErrorsDialog, setShowErrorsDialog] = useState<boolean>(false);
  const [errors, setErrors] = useState<ApiError[]>([]);
  const [step, setStep] = useState<number>(0);
  const [provinces, setProvinces] = useState<State[]>([]);

  useEffect(() => {
    const loadProvinces = async () => {
      const res = await MarketplaceService.getStates();
      setProvinces(res.data);
    };

    loadProvinces();
  }, []);

  /**
   * Hide Personal info fields if group is the only
   * option selected
   */
  useEffect(() => {
    if (!!insuranceSelected.find(ins => ins === InsuranceType.Group)) {
      if (insuranceSelected.length === 1) setWithPersonalFields(false);
    }
  }, [insuranceSelected]);

  const displayErrors = (errors: ApiError[]) => {
    setErrors(errors);
    setShowErrorsDialog(true);
  };

  return (
    <>
      <div className="text-center">
        {!step && (
          <InsuranceTypeForm
            step={step}
            setStep={setStep}
            setInsuranceSelected={setInsuranceSelected}
          />
        )}

        {step === 1 && (
          <ContactForm
            step={step}
            setStep={setStep}
            withPersonalFields={withPersonalFields}
            provinces={provinces}
            setMarketplaceId={setMarketplaceId}
            displayErrors={e => displayErrors(e)}
          />
        )}

        {step === 2 && (
          <AdditionalInfoForm
            marketplaceId={marketplaceId}
            step={step}
            setStep={setStep}
            insuranceSelected={insuranceSelected}
            displayErrors={e => displayErrors(e)}
          />
        )}

        {step === 3 && (
          <SubmitQuoteForm
            step={step}
            setStep={setStep}
            marketplaceId={marketplaceId}
            displayErrors={e => displayErrors(e)}
          />
        )}

        {step === 4 && <QuoteComplete />}
      </div>
      {showErrorsDialog && (
        <ErrorDialog
          errors={errors}
          onDismiss={() => setShowErrorsDialog(false)}
        />
      )}
    </>
  );
}
