import React from 'react';
import InputError from './input-error';

interface Props {
  register: () => void;
  type: 'text' | 'date' | 'email' | 'tel' | 'url' | 'number';
  name: string;
  label: string;
  placeholder?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  errors?: any;
}

export default function Input(props: Props): React.ReactElement {
  return (
    <>
      <label htmlFor={props.name} className="text-center form-label">
        {props.label}
      </label>
      <div className="relative mt-1">
        <input
          id={props.name}
          name={props.name}
          type={props.type}
          className="block w-full max-w-sm mx-auto form-input sm:text-sm sm:leading-5"
          placeholder={props.placeholder}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          ref={props.register}
        />
        <div className="max-w-sm mx-auto">
          <InputError error={props.errors[props.name]?.message} />
        </div>
      </div>
    </>
  );
}
