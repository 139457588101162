import InsuranceQuotes from '../insurance-quotes';
import { Insurance } from './insurance';
import { MediaType } from './media';

export class InsuranceQuote {
  public title!: string;
  public mediaType!: MediaType;
  public payload!: Insurance;
  public smoking!: boolean;
  public coveragePeriod!: boolean;
  public coverageAmount!: boolean;
  public disability!: boolean;

  constructor(type: string) {
    this.initInsuranceQuote(type);
  }

  private initInsuranceQuote(type: string) {
    const q = InsuranceQuotes().find(q => q.payload.type === type);

    if (q) {
      Object.assign(this, q);
    }
  }
}
