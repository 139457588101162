import { ReactElement } from 'react';

import React from 'react';
import { InsuranceType } from '../data/models';

interface Props {
  type: InsuranceType;
  label: string;
  icon: string;
  register: () => void;
}

export default function InsuranceTypeCheckbox({
  type,
  label,
  icon,
  register,
}: Props): ReactElement {
  return (
    <div className="inline-block form-radio-checkbox--circle">
      <input
        type="checkbox"
        id={type}
        name="insurance"
        value={type}
        ref={register}
      />
      <label className="mx-2 lg:mx-4" htmlFor={type}>
        <img src={icon} alt="{label}" className="mt-2 max-w-95" />
        <br />
      </label>
    </div>
  );
}
