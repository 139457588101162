import React from 'react';

interface Props {
  error: string | undefined;
}

const InputError: React.FC<Props> = ({ error }: Props) => {
  return (
    <>
      {error && (
        <div className="px-3 py-1 text-xs font-bold leading-tight text-red-700 bg-red-100 border border-red-300 rounded">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};

export default InputError;
