import React, { ReactElement } from 'react';
import customIconMailSent from '../img/misc-icons/icon_mail_sent_purple.svg';

export default function QuoteComplete(): ReactElement {
  return (
    <div className="max-w-6xl m-auto bg-white rounded-lg bg-honeycomb">
      <div className="px-4 pt-6 pb-2 md:pt-10 sm:px-6">
        <h1 className="mx text-3xl md:text-5xl font-bold text-center uppercase  text-primaryMed font-sansAlt">
          Almost there!
        </h1>
      </div>
      <div className="px-4 py-0 sm:p-6 space-y-6">
        <div className="w-48 my-0 mx-auto max-w-95">
          <img src={customIconMailSent} alt="." className="w-full -ml-1 opacity-75" />
        </div>
        <p className="mt-4 md:mt-2 mb-4 text-xl md:text-2xl font-bold leading-none text-gray-500 font-sansAlt">
          Don’t forget to check your email and confirm your address.
        </p>
        <p>
          We’ve received your details and one of our PROs will get back to you
          with a quote within a few days.
        </p>
        <p className="text-black font-semibold text-lg pt-6 pb-10">
          Thanks for choosing Ingenious Insurance.
        </p>
      </div>
    </div>
  );
}
