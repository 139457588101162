import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

interface Props {
  isSmoker: boolean | undefined;
}

export default function SmokingField({ isSmoker }: Props): ReactElement {
  const { register, errors } = useFormContext();

  return (
    <div>
      <div className="mb-4">
        <label
          htmlFor="smoking"
          className="block mx-auto my-4 text-center md:max-w-2xl"
        >
          Within the past 12 months, have you used by any means, a substance or
          product containing tobacco or nicotine (excluding cigars), or have you
          smoked (including electronic vaporizer or “vaping”) marijuana more
          than six times per week?
        </label>
        <div className="flex mt-4 justify-evenly">
          <div className="form-radio-checkbox--square">
            <input
              id="smoker"
              name="smoking"
              value="true"
              type="radio"
              className=""
              defaultChecked={isSmoker}
              ref={register}
            />
            <label htmlFor="smoker" className="">
              <span className="inline-block w-10 h-10 mr-1 border border-grey"></span>
              Yes
            </label>
          </div>
          <div className="form-radio-checkbox--square">
            <input
              id="non-smoker"
              name="smoking"
              value="false"
              type="radio"
              className=""
              defaultChecked={isSmoker === false}
              ref={register}
            />
            <label htmlFor="non-smoker" className="">
              <span></span>
              No
            </label>
          </div>
        </div>
        <div className="mx-auto md:max-w-2xl">
          <InputError error={errors.smoking?.message} />
        </div>
      </div>
    </div>
  );
}
