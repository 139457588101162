import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import {
  MarketplaceRequestPayload,
  DataType,
  MarketplaceRequestStatus,
} from '../data/models';
import { MarketplaceService } from '../data';
import { AxiosError } from 'axios';
import { ApiError } from '../data/models/api-error';

interface Props {
  step: number;
  marketplaceId: string;
  setStep: Dispatch<SetStateAction<number>>;
  displayErrors: (errors: ApiError[]) => void;
}

export default function SubmitQuoteForm({
  step,
  marketplaceId,
  setStep,
  displayErrors,
}: Props): ReactElement {
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const payload: MarketplaceRequestPayload = {
      data: {
        id: marketplaceId,
        type: DataType.MarketplaceRequest,
        attributes: {
          status: MarketplaceRequestStatus.Complete,
        },
      },
    };

    try {
      await MarketplaceService.updateMarketplace(marketplaceId, payload);
      setStep(++step);
    } catch (error) {
      displayErrors((error as AxiosError).response?.data?.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-6xl py-6 m-auto bg-white rounded-lg">
        <h1 className="mt-4 mb-6 text-2xl font-bold text-center uppercase md:text-3xl text-primaryMed font-sansAlt">
          <span className="block">Submit and verify</span>
          <span className="block text-lg md:text-2xl">your email address</span>
        </h1>
        <div className="px-4 py-5 sm:p-6">
          <p className="block mx-auto my-4 text-center md:max-w-2xl md:text-lg">
            After you hit <em>Submit</em> you will receive an email &mdash;
            please click on the link so we can verify that it’s you. We will use
            the email address you provided here to send your insurance quotes.
          </p>
        </div>
        <div className="px-4 py-4 sm:px-6">
          <button type="submit" className="w-full button-primary">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
