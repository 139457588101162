import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

export default function ContactInfoFields(): React.ReactElement {
  const { register, errors } = useFormContext();

  return (
    <>
      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="first_name" className="text-center form-label">
            First Name
          </label>
          <input
            id="first_name"
            name="first_name"
            type="text"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            placeholder="John"
            ref={register}
          />
          <InputError error={errors.first_name?.message} />
        </div>
      </div>

      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="last_name" className="text-center form-label">
            Last Name
          </label>
          <input
            id="last_name"
            name="last_name"
            type="text"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            placeholder="Doe"
            ref={register}
          />
          <InputError error={errors.last_name?.message} />
        </div>
      </div>

      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="email" className="text-center form-label">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            placeholder="john.doe@example.com"
            aria-describedby="email-description"
            ref={register}
          />
          <InputError error={errors.email?.message} />
        </div>
      </div>

      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="phone" className="text-center form-label">
            Phone Number
          </label>
          <input
            id="phone"
            name="phone"
            type="tel"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            placeholder="(555) 555-555"
            ref={register}
          />
          <InputError error={errors.phone?.message} />
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="preferred_contact" className="text-center form-label">
          Preferred Contact
        </label>
        <select
          id="preferred_contact"
          name="preferred_contact"
          aria-label="Preferred Contact"
          className="relative block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 transition duration-150 ease-in-out bg-transparent border-gray-300 rounded-md form-select focus:z-10 form-input font-sansCondensed"
          ref={register}
        >
          <option value="phone">Phone</option>
          <option value="email">Email</option>
        </select>
        <InputError error={errors.preferred_contact?.message} />
      </div>

      <div className="mb-4">
        <div className="relative mt-1 rounded-md">
          <label htmlFor="time_to_call" className="text-center form-label">
            Best time to call
          </label>
          <input
            id="time_to_call"
            name="time_to_call"
            type="text"
            className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md form-input font-sansCondensed"
            placeholder="After 9AM"
            ref={register}
          />
        </div>
      </div>
    </>
  );
}
