import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

export default function DisabilityFields(): ReactElement {
  const { register, errors } = useFormContext();

  return (
    <>
      <div className="mb-4">
        <label htmlFor="occupation" className="text-center form-label">
          What is your occupation?
        </label>
        <div className="relative mt-1">
          <input
            id="occupation"
            name="occupation"
            type="text"
            className="block w-full max-w-sm mx-auto form-input sm:text-sm sm:leading-5"
            placeholder="Occupation"
            ref={register}
          />
          <div className="max-w-sm mx-auto">
            <InputError error={errors.occupation?.message} />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="job_title" className="text-center form-label">
          What is your job title?
        </label>
        <div className="relative mt-1">
          <input
            id="job_title"
            name="job_title"
            type="text"
            className="block w-full max-w-sm mx-auto form-input sm:text-sm sm:leading-5"
            placeholder="Job title"
            ref={register}
          />
          <div className="max-w-sm mx-auto">
            <InputError error={errors.job_title?.message} />
          </div>
        </div>
      </div>
      <div className="mb-4 md:mb-6">
        <label htmlFor="income" className="text-center form-label">
          What is your gross annual income?
        </label>
        <div className="relative mt-1">
          <input
            id="income"
            name="income"
            type="number"
            className="block w-full max-w-sm mx-auto form-input sm:text-sm sm:leading-5"
            placeholder="income"
            ref={register}
          />
          <div className="max-w-sm mx-auto">
            <InputError error={errors.income?.message} />
          </div>
        </div>
      </div>
    </>
  );
}
