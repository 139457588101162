import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import { MarketplaceService } from '../data';
import { Media, MediaType } from '../data/models';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

interface Props {
  title: string;
  marketplaceId: string;
  uploadedMedia: Media[];
  mediaType: MediaType;
  setUploadedMedia: Dispatch<SetStateAction<Media[]>>;
}

export default function MediaUploadField({
  title,
  marketplaceId,
  uploadedMedia,
  mediaType,
  setUploadedMedia,
}: Props): ReactElement {
  const { register, errors } = useFormContext();
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    const files = e.target.files;

    if (files && files.length && mediaType) {
      const file: File = files[0];

      if (file.size < 4000000) {
        formData.append('file', file);
        formData.append('type', mediaType);

        uploadMedia(formData);
      }
    }
  };

  const uploadMedia = async (media: FormData) => {
    const res = await MarketplaceService.uploadMarketplaceMedia(
      marketplaceId,
      media
    );
    setUploadedMedia([res.data, ...uploadedMedia]);
  };

  return (
    <div>
      <p>
        If you have an existing policy or policies, you may upload them here:
      </p>
      <div>
        <label htmlFor="file" className="form-label">
          Upload existing policy
        </label>
        <div className="relative mt-4 mb-4 overflow-hidden">
          <input
            id="file"
            name="file"
            type="file"
            accept=".pdf"
            className="block w-full mx-auto md:max-w-lg form-input sm:text-sm sm:leading-5"
            onChange={e => onChange(e)}
            ref={register}
          />
          <div className="mx-auto md:max-w-lg">
            <InputError error={errors.file?.message} />
          </div>
        </div>
      </div>

      {uploadedMedia && !!uploadedMedia.length && (
        <div>
          <h3 className="text-lg">Uploaded {title} Policies</h3>
          <ul>
            {uploadedMedia.map(media => (
              <li key={media.id}>{media.attributes.file_name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
