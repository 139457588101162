import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MarketplaceService } from '../data';
import {
  DataType,
  Insurance,
  InsuranceType,
  MarketplaceRequestPayload,
  Media,
  MediaType,
} from '../data/models';
import { InsuranceQuote } from '../data/models/insurance-quote';
import CoverageAmountField from './coverage-amount-field';
import CoveragePeriodField from './coverage-period-field';
import DisabilityFields from './disability-fields';
import MediaUploadField from './media-upload-field';
import SmokingField from './smoking-field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import GroupFields from './group-fields';
import { ApiError } from '../data/models/api-error';
import { AxiosError } from 'axios';

interface Props {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  insuranceSelected: string[];
  marketplaceId: string;
  displayErrors: (errors: ApiError[]) => void;
}

type Inputs = {
  mediaType?: MediaType;
  file?: File;
  type: InsuranceType;
  smoking: string;
  coverage_period: number | string;
  coverage_amount: number;
  occupation: string;
  job_title: string;
  income: number;
  organization_name: string;
  industry: string;
  business_start_at: number;
  website: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postal_code: string;
};

export default function AdditionalInfoForm({
  step,
  setStep,
  insuranceSelected,
  marketplaceId,
  displayErrors,
}: Props): ReactElement {
  const [currentPolicy, setCurrentPolicy] = useState<number>(0);
  const [insuranceTypes, setInsuranceTypes] = useState<Insurance[]>([]);
  const [uploadedMedia, setUploadedMedia] = useState<Media[]>([]);
  const [isSmoker, setIsSmoker] = useState<boolean | undefined>();
  const [quote, setQuote] = useState<InsuranceQuote>();
  const [isGroup, setIsGroup] = useState<boolean | undefined>();

  const requiredString = yup.string().required();
  const requiredNumber = yup.number().required('Must be a number');
  const mixSchema = yup.mixed();

  const groupSchema = {
    organization_name: requiredString,
    industry: requiredString,
    business_start_at: requiredNumber.test(
      'len',
      'Must be exactly 4 digits long',
      val => typeof val == 'number' && val.toString().length === 4
    ),
    website: requiredString,
    address1: requiredString,
    address2: yup.string().optional(),
    city: requiredString,
    postal_code: requiredString,
    state: yup.string().required().notOneOf([''], 'You must select a province'),
  };

  const schema = yup.object().shape({
    ...(quote?.smoking && { smoking: requiredString }),
    ...(quote?.coverageAmount && { coverage_amount: requiredNumber }),
    ...(quote?.disability && { occupation: requiredString }),
    ...(quote?.disability && { job_title: requiredString }),
    ...(quote?.disability && { income: requiredNumber }),
    ...(quote?.coveragePeriod && {
      coverage_period: mixSchema
        .required()
        .test(
          'coverage_period',
          'coverage_period is required',
          val =>
            typeof val === 'number' ||
            (typeof val === 'string' && val.length > 0)
        ),
    }),
    ...(isGroup && { ...groupSchema }),
    file: mixSchema.test('fileSize', 'Files can not be larger than 4MB', f =>
      f.length ? f[0].size < 4000000 : true
    ),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const loadMeta = () => {
    const insQuote = new InsuranceQuote(insuranceSelected[currentPolicy]);
    setQuote(insQuote);
    setIsGroup(insQuote.payload.type === InsuranceType.Group);
  };

  useEffect(() => {
    loadMeta();
  }, [currentPolicy]);

  const onSubmit = async (data: Inputs) => {
    const {
      smoking,
      coverage_amount,
      occupation,
      job_title,
      income,
      coverage_period,
      organization_name,
      industry,
      business_start_at,
      website,
      address1,
      address2,
      city,
      state,
      postal_code,
    } = data;

    let smokingBoolean = false;

    if (smoking && smoking.length) {
      smokingBoolean = JSON.parse(smoking);
      setIsSmoker(smokingBoolean);
    }

    if (quote) {
      const payload: MarketplaceRequestPayload = {
        data: {
          type: DataType.MarketplaceRequest,
          id: marketplaceId,
          attributes: {
            insurance_types: [
              ...insuranceTypes,
              {
                ...quote.payload,
                ...(smoking && { smoking: smokingBoolean }),
                ...(coverage_amount && { coverage_amount }),
                ...(occupation && { occupation }),
                ...(job_title && { job_title }),
                ...(income && { income }),
                ...(coverage_period && { coverage_period }),
                ...(organization_name && { organization_name }),
                ...(industry && { industry }),
                ...(business_start_at && { business_start_at }),
                ...(website && { website }),
                ...(address1 && { address1 }),
                ...(address2 && { address2 }),
                ...(city && { city }),
                ...(state && { state }),
                ...(postal_code && { postal_code }),
              },
            ],
          },
        },
      };

      delete data.file;
      delete data.mediaType;

      try {
        const res = await MarketplaceService.updateMarketplace(
          marketplaceId,
          payload
        );

        setInsuranceTypes(res.data.attributes.insurance_types);

        if (currentPolicy + 1 < insuranceSelected.length) {
          // Reset
          setUploadedMedia([]);
          methods.reset();

          setCurrentPolicy(currentPolicy + 1);
        } else {
          setStep(++step);
        }
      } catch (error) {
        displayErrors((error as AxiosError).response?.data?.errors);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="max-w-6xl m-auto overflow-hidden bg-white">
          {quote && (
            <>
              <h1 className="mb-6 text-3xl font-bold tracking-wide text-center uppercase text-primaryMed font-sansCondensed">
                {quote.title}
              </h1>
              <div className="px-4 py-5 md:flex md:justify-between sm:p-6">
                <div className="mx-auto contact__info md:flex-grow md:max-w-4xl md:pr-6">
                  {quote.disability && <DisabilityFields />}

                  {quote.coveragePeriod && <CoveragePeriodField />}

                  {quote.coverageAmount && <CoverageAmountField />}

                  {quote.smoking && <SmokingField isSmoker={isSmoker} />}

                  {isGroup && <GroupFields />}

                  <MediaUploadField
                    title={quote.title}
                    marketplaceId={marketplaceId}
                    uploadedMedia={uploadedMedia}
                    mediaType={quote.mediaType}
                    setUploadedMedia={setUploadedMedia}
                  />
                </div>
              </div>
              <div className="px-4 py-4 sm:px-6">
                <button type="submit" className="w-full button-primary">
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
