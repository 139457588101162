import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogLabel,
} from '@reach/alert-dialog';
import React from 'react';
import { ApiError } from '../data/models/api-error';

interface Props {
  errors: ApiError[];
  onDismiss: (dismiss: boolean) => void;
}

export default function ErrorDialog({
  errors,
  onDismiss,
}: Props): React.ReactElement {
  const cancelRef: any = React.useRef();

  return (
    <>
      <AlertDialog
        className="px-3 py-1 overflow-hidden font-bold leading-tight text-left text-red-700 align-bottom transition-all transform bg-red-100 border border-red-300 rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-3"
        leastDestructiveRef={cancelRef}
      >
        <div className="flex justify-end">
          <button
            className="close-button"
            onClick={() => onDismiss(false)}
            ref={cancelRef}
          >
            <span className="sr-only">Close</span>
            <span aria-hidden className="text-xl">
              ×
            </span>
          </button>
        </div>
        <AlertDialogLabel
          className="mb-5 text-xl font-bold leading-6 text-gray-900"
          id="modal-headline"
        >
          We have encountered the following error(s)
        </AlertDialogLabel>
        <AlertDialogDescription className="mt-2">
          <ul className="ml-5 list-disc list-outside">
            {errors.map((error, i) => (
              <li key={i} className="my-2">
                {error.title}: {error.detail}
              </li>
            ))}
          </ul>
        </AlertDialogDescription>
      </AlertDialog>
    </>
  );
}
