import axios from 'axios';
import AppConfig from '../config/app.config';

const Api = axios.create({
  baseURL: `${AppConfig.api.url}`,
});

Api.interceptors.request.use(config => config);

export default Api;
