import { MediaType, Insurance, InsuranceType } from './models';
import insTypeIconAuto from '../img/ins-type-icons/ins_type_icon_auto.svg';
import insTypeIconCommercial from '../img/ins-type-icons/ins_type_icon_commercial.svg';
import insTypeIconCriticalIllness from '../img/ins-type-icons/ins_type_icon_critical-illness.svg';
import insTypeIconDisabilities from '../img/ins-type-icons/ins_type_icon_disabilities.svg';
import insTypeIconHealthDental from '../img/ins-type-icons/ins_type_icon_health-dental.svg';
import insTypeIconHome from '../img/ins-type-icons/ins_type_icon_home.svg';
import insTypeIconLiability from '../img/ins-type-icons/ins_type_icon_liability.svg';
import insTypeIconLife from '../img/ins-type-icons/ins_type_icon_life.svg';
import insTypeIconTravel from '../img/ins-type-icons/ins_type_icon_travel.svg';
import insTypeIconGroup from '../img/ins-type-icons/ins_type_icon_group.svg';

type InsuranceQuoteType = {
  title: string;
  mediaType: MediaType;
  payload: Partial<Insurance>;
  smoking: boolean;
  coveragePeriod: boolean;
  coverageAmount: boolean;
  disability: boolean;
  icon: string;
};

const InsuranceQuotes = (): InsuranceQuoteType[] => [
  {
    title: 'Auto',
    mediaType: MediaType.MarketplaceAuto,
    payload: { type: InsuranceType.Auto },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconAuto,
  },
  {
    title: 'Commercial',
    mediaType: MediaType.MarketplaceCommercial,
    payload: { type: InsuranceType.Commercial },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconCommercial,
  },
  {
    title: 'Critical Illness',
    mediaType: MediaType.MarketplaceCriticalIllness,
    payload: { type: InsuranceType.CriticalIllness },
    smoking: true,
    coveragePeriod: false,
    coverageAmount: true,
    disability: false,
    icon: insTypeIconCriticalIllness,
  },
  {
    title: 'Disability',
    mediaType: MediaType.MarketplaceDisability,
    payload: { type: InsuranceType.Disability },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: true,
    icon: insTypeIconDisabilities,
  },
  {
    title: 'Health and Dental',
    mediaType: MediaType.MarketplaceHealthAndDental,
    payload: { type: InsuranceType.HealthAndDental },
    smoking: true,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconHealthDental,
  },
  {
    title: 'Home',
    mediaType: MediaType.MarketplaceHome,
    payload: { type: InsuranceType.Home },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconHome,
  },
  {
    title: 'Liability',
    mediaType: MediaType.MarketplaceLiability,
    payload: { type: InsuranceType.Liability },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconLiability,
  },
  {
    title: 'Life',
    mediaType: MediaType.MarketplaceLife,
    payload: { type: InsuranceType.Life },
    smoking: true,
    coveragePeriod: true,
    coverageAmount: true,
    disability: false,
    icon: insTypeIconLife,
  },
  {
    title: 'Travel',
    mediaType: MediaType.MarketplaceTravel,
    payload: { type: InsuranceType.Travel },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconTravel,
  },
  {
    title: 'Group',
    mediaType: MediaType.MarketplaceGroup,
    payload: { type: InsuranceType.Group },
    smoking: false,
    coveragePeriod: false,
    coverageAmount: false,
    disability: false,
    icon: insTypeIconGroup,
  },
];

export default InsuranceQuotes;
