import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from './input-error';

export default function CoverageAmountField(): ReactElement {
  const { register, errors } = useFormContext();

  return (
    <div className="mb-6">
      <label htmlFor="coverage_amount" className="text-center form-label">
        What coverage amount are you looking for?
      </label>
      <div className="relative mt-1">
        <input
          id="coverage_amount"
          name="coverage_amount"
          type="number"
          className="block w-full max-w-sm mx-auto form-input sm:text-sm sm:leading-5"
          placeholder="Amount"
          ref={register}
        />
        <div className="max-w-sm mx-auto">
          <InputError error={errors.coverage_amount?.message} />
        </div>
      </div>
    </div>
  );
}
