import { yupResolver } from '@hookform/resolvers';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InsuranceType } from '../data/models';
import InsuranceQuotes from '../data/insurance-quotes';
import InsuranceTypeCheckbox from './insurance-type-checkbox';

interface Props {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  setInsuranceSelected: Dispatch<SetStateAction<string[]>>;
}

type Inputs = {
  insurance: string[];
};

const schema = yup.object().shape({
  insurance: yup.array().required(),
});

export default function InsuranceTypeForm({
  step,
  setStep,
  setInsuranceSelected,
}: Props): ReactElement {
  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: Inputs) => {
    setStep(++step);
    setInsuranceSelected(data.insurance);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-6xl m-auto overflow-hidden bg-white rounded-lg bg-honeycomb">
        <div className="flex flex-col px-4 py-5 md:flex-row md:justify-between sm:p-6">
          <div className="md:flex-grow md:w-5/12 md:text-left">
            <h1 className="text-lg font-bold uppercase text-primaryMed font-sansCondensed">
              Insurance Market Place
              <span className="hidden mb-1 md:inline-block md:ml-2 p0 md:w-16 lg:w-48 md:h-1 md:rounded bg-primaryMed"></span>
            </h1>

            <h2 className="mt-4 mb-4 text-4xl font-bold leading-none text-gray-400 uppercase md:mt-2 md:text-6xl font-sansAlt">
              Get a
              <br />
              Quote
            </h2>

            <p>Select your insurance types and press next.</p>
            <button
              type="submit"
              className="hidden w-32 mt-16 button-primary md:inline-block"
              disabled={!formState.isValid}
            >
              Next
            </button>
          </div>
          <div className="mt-6 text-center bg-ring-lines md:flex-grow md:w-7/12 md:mt-0">
            <div className="max-w-6xl md:px-6">
              {InsuranceQuotes().map(quote => (
                <InsuranceTypeCheckbox
                  key={quote.payload.type}
                  type={quote.payload.type as InsuranceType}
                  label={quote.title}
                  icon={quote.icon}
                  register={register}
                />
              ))}
            </div>
            <button
              type="submit"
              className="w-32 mt-6 button-primary md:hidden"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
