export enum Industry {
  Accommodation = 'Accommodation',
  Administrative = 'Administrative',
  Agriculture = 'Agriculture',
  Arts = 'Arts',
  Construction = 'Construction',
  Educational = 'Educational',
  Finance = 'Finance',
  Healthcare = 'Healthcare',
  Information = 'Information',
  Management = 'Management',
  Manufacturing = 'Manufacturing',
  Mining = 'Mining',
  OtherServices = 'OtherServices',
  Professional = 'Professional',
  PublicAdministration = 'PublicAdministration',
  RealEstate = 'RealEstate',
  Retail = 'Retail',
  Transportation = 'Transportation',
  Utilities = 'Utilities',
  Wholesale = 'Wholesale',
}
