import { yupResolver } from '@hookform/resolvers';
import { AxiosError } from 'axios';
import { isBefore, isPast, parse, sub } from 'date-fns';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { MarketplaceService } from '../data';
import {
  Client,
  DataType,
  MarketplaceRequestPayload,
  State,
} from '../data/models';
import { ApiError } from '../data/models/api-error';
import ContactInfoFields from './contact-info-fields';
import PersonalInfoFields from './personal-info-fields';

interface Props {
  step: number;
  provinces: State[];
  withPersonalFields: boolean;
  setStep: Dispatch<SetStateAction<number>>;
  setMarketplaceId: Dispatch<SetStateAction<string>>;
  displayErrors: (errors: ApiError[]) => void;
}

type Inputs = Client;

export default function ContactForm({
  step,
  provinces,
  withPersonalFields,
  setStep,
  setMarketplaceId,
  displayErrors,
}: Props): ReactElement {
  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    preferred_contact: yup.string().required(),
    ...(withPersonalFields && { gender: yup.string().required() }),
    ...(withPersonalFields && {
      born_at: yup
        .string()
        .required()
        .test('born_at', 'DOB cannot be in the future', val =>
          isPast(parse(val as string, 'yyyy-MM-dd', new Date()))
        )
        .test('born_at', 'DOB cannot be this recent', val =>
          isBefore(
            parse(val as string, 'yyyy-MM-dd', new Date()),
            sub(new Date(), { years: 10 })
          )
        ),
    }),
    ...(withPersonalFields && {
      state_residence: yup
        .string()
        .required()
        .notOneOf([''], 'You must select a province'),
    }),
  });

  const form = useForm<Inputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (client: Inputs) => {
    const payload: MarketplaceRequestPayload = {
      data: {
        type: DataType.MarketplaceRequest,
        attributes: {
          client,
        },
      },
    };

    try {
      const res = await MarketplaceService.createMarketplace(payload);
      setMarketplaceId(res.data.id);
      setStep(++step);
    } catch (error) {
      displayErrors((error as AxiosError).response?.data?.errors);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="max-w-6xl m-auto overflow-hidden bg-white rounded-lg">
          <h1 className="sr-only">Contact Information</h1>
          <div className="px-4 py-5 md:flex md:justify-between sm:p-6">
            <div className="contact__info md:flex-grow md:pr-6">
              <h2 className="mb-6 text-xl font-bold tracking-wide text-center uppercase text-primaryMed font-sansCondensed">
                Contact Information
              </h2>
              <ContactInfoFields />
            </div>

            {withPersonalFields && (
              <div className="mt-6 contact__personal md:flex-grow md:mt-0">
                <h2 className="mb-6 text-xl font-bold tracking-wide text-center uppercase text-primaryMed font-sansCondensed md:pl-6">
                  Personal Information
                </h2>
                <PersonalInfoFields provinces={provinces} />
              </div>
            )}
          </div>
          <div className="px-4 py-4 sm:px-6">
            <button type="submit" className="w-full button-primary">
              Next
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
