import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MarketplaceService } from '../data';
import { State } from '../data/models';
import { Industry } from '../data/models/industry';
import Input from './input';
import InputError from './input-error';

export default function GroupFields(): React.ReactElement {
  const { register, errors } = useFormContext();
  const [provinces, setProvinces] = useState<State[]>([]);

  const currentYear = new Date().getFullYear();
  const industries = Object.values(Industry);

  useEffect(() => {
    const loadProvinces = async () => {
      const res = await MarketplaceService.getStates();
      setProvinces(res.data);
    };

    loadProvinces();
  }, []);

  return (
    <>
      <div className="px-4 py-5 md:flex md:justify-between sm:p-6">
        <div className="md:flex-grow md:pr-6">
          <h2 className="mb-6 text-xl font-bold tracking-wide text-center uppercase text-primaryMed font-sansCondensed">
            Company Information
          </h2>
          <div className="mb-4">
            <Input
              label="Organization name"
              name="organization_name"
              placeholder="Organization"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="industry" className="text-center form-label">
              Industry
            </label>
            <div className="relative mt-1">
              <select
                id="industry"
                name="industry"
                aria-label="Industry"
                className="block w-full max-w-sm py-2 mx-auto form-input sm:text-sm sm:leading-5 form-select"
                ref={register}
              >
                <option value="">Select industry</option>
                {industries &&
                  industries.map(industry => (
                    <option
                      className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md cursor-pointer form-input font-sansCondensed"
                      key={industry}
                      value={industry}
                    >
                      {industry}
                    </option>
                  ))}
              </select>
              <InputError error={errors?.industry?.message} />
            </div>
          </div>
          <div className="mb-4">
            <Input
              label="In business since"
              name="business_start_at"
              placeholder="YYYY"
              type="number"
              min={1000}
              max={currentYear}
              maxLength={4}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mb-4">
            <Input
              label="Website"
              name="website"
              placeholder="Website"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
        </div>
        <div className="md:flex-grow md:pr-6">
          <h2 className="mb-6 text-xl font-bold tracking-wide text-center uppercase text-primaryMed font-sansCondensed">
            Business Address
          </h2>
          <div className="mb-4">
            <Input
              label="Address"
              name="address1"
              placeholder="Address"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="mb-4">
            <Input
              label="Unit"
              name="address2"
              placeholder="Unit"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="mb-4">
            <Input
              label="City"
              name="city"
              placeholder="City"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="text-center form-label">
              Province
            </label>
            <div className="relative mt-1">
              <select
                id="state"
                name="state"
                aria-label="Province"
                className="block w-full max-w-sm py-2 mx-auto form-input sm:text-sm sm:leading-5 form-select"
                ref={register}
              >
                <option value="">Select province</option>
                {provinces &&
                  provinces.map(province => (
                    <option
                      className="block w-full h-16 px-3 py-2 text-2xl leading-6 text-gray-600 placeholder-gray-400 border-gray-300 rounded-md cursor-pointer form-input font-sansCondensed"
                      key={province.id}
                      value={province.id}
                    >
                      {province.attributes.name}
                    </option>
                  ))}
              </select>
              <InputError error={errors?.state?.message} />
            </div>
          </div>
          <div className="mb-4">
            <Input
              label="Postal code"
              name="postal_code"
              placeholder="Postal code"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </>
  );
}
