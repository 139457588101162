import Api from './api';
import {
  MarketplaceRequestResponse,
  MarketplaceRequestPayload,
  StatesResponse,
  MediaResponse,
} from './models';

export class MarketplaceService {
  private static readonly INCLUDE_MEDIA = { include: 'media' };

  public static async getMarketplace(
    id: string
  ): Promise<MarketplaceRequestResponse> {
    const res = await Api.get<MarketplaceRequestResponse>(
      `/marketplace-request/${id}`,
      { params: MarketplaceService.INCLUDE_MEDIA }
    );
    return res.data;
  }

  public static async createMarketplace(
    payload: MarketplaceRequestPayload
  ): Promise<MarketplaceRequestResponse> {
    const res = await Api.post<MarketplaceRequestResponse>(
      `/marketplace-request`,
      payload
    );
    return res.data;
  }

  public static async updateMarketplace(
    id: string,
    payload: MarketplaceRequestPayload
  ): Promise<MarketplaceRequestResponse> {
    const res = await Api.patch<MarketplaceRequestResponse>(
      `/marketplace-request/${id}`,
      payload
    );
    return res.data;
  }

  public static async uploadMarketplaceMedia(
    marketplaceId: string,
    media: FormData
  ): Promise<MediaResponse> {
    const res = await Api.post<MediaResponse>(
      `/marketplace-request/${marketplaceId}/media`,
      media
    );
    return res.data;
  }

  public static async getStates(countryId = 'CA'): Promise<StatesResponse> {
    const res = await Api.get<StatesResponse>(`/states/${countryId}`);
    return res.data;
  }
}
