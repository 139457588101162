import { Client } from './client';
import { Insurance } from './insurance';
import { DataType } from './data-type';
import { MediaResponse } from './media';

export enum MarketplaceRequestStatus {
  Complete = 'Complete',
}

export type MarketplaceRequestAttributes = {
  client: Client;
  insurance_types: Insurance[];
  status: MarketplaceRequestStatus;
};

export type MarketplaceRequestRelationships = {
  media?: {
    data: { type: 'media'; id: string }[];
  };
};

export type MarketplaceRequestPayload = {
  data: {
    id?: string;
    type: DataType.MarketplaceRequest;
    attributes: Partial<MarketplaceRequestAttributes>;
  };
};

export type MarketplaceRequestResponse = {
  data: {
    id: string;
    type: DataType.MarketplaceRequest;
    attributes: MarketplaceRequestAttributes;
    relationships?: MarketplaceRequestRelationships;
  };
  included?: MediaResponse[];
};
