export enum PreferredContact {
  Phone = 'phone',
  Email = 'email',
}

export type Client = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  preferred_contact: PreferredContact;
  time_to_call: string;
  gender: string;
  born_at: string;
  state_residence: string;
};
